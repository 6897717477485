<template>
  <div class="col-6">
    <h3>Team Totals</h3>
    <ul class="list-unstyled team-totals">
      <li>
        <p class="label">Clients</p>
        <p>{{ clientCount }}</p>
      </li>
      <li>
        <p class="label">Locations</p>
        <p>{{ locationCount }}</p>
      </li>
      <li>
        <p class="label">Partners</p>
        <p>{{ partnerCount }}</p>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: "TeamTotals",
  props: {
    locations: {
      type: Array,
      required: true,
    },
  },
  computed: {
    clientCount() {
      const clients = this.$props.locations.map((location) => location.clientID).filter((value, index, self) => self.indexOf(value) === index) || [];
      return clients.length;
    },
    locationCount() {
      return this.$props.locations.length;
    },
    partnerCount() {
      let count = 0;
      this.$props.locations.forEach((location) => {
        count += location.partners_aggregate.aggregate.count;
      });
      return count;
    },
  },
};
</script>
